import React from 'react'
import { getIntelligentAskAmount } from 'services/intelligentAskAmount/intelligent-ask-amount'
import { EVENT } from 'models/event'
import { PageConfig } from 'models/pages'

/**
 * Custom React hook that manages fetching and dispatching Intelligent Ask Amounts.
 */

export const useIntelligentAskAmount = (
  campaignId: PageConfig['campaignId'],
  intelligentAskAmount: PageConfig['intelligentAskAmount'],
) => {
  const intelligentAskAmounts = React.useCallback(async () => {
    if (!campaignId) return

    const iaaBaseAmounts = await getIntelligentAskAmount(campaignId)
    document.dispatchEvent(
      new CustomEvent(EVENT.IAA_FETCH_AMOUNTS, {
        detail: {
          ...iaaBaseAmounts,
          isLoading: false,
        },
      }),
    )
  }, [campaignId])

  React.useEffect(() => {
    if (intelligentAskAmount?.onetimeEnabled || intelligentAskAmount?.recurringEnabled) {
      intelligentAskAmounts()
    }
  }, [intelligentAskAmount, intelligentAskAmounts])
}
