import Head from 'next/head'

interface OpenGraphTagsProps {
  facebookText: string
  facebookImageUrl: string
  orgName: string
  currentUrl: string
  facebookAppId?: string
}

/**
 * Mark up current page with Open Graph tags
 * https://developers.facebook.com/docs/sharing/webmasters
 */

export const OpenGraphTags = ({
  facebookText,
  facebookImageUrl,
  orgName,
  currentUrl,
  facebookAppId,
}: OpenGraphTagsProps) => {
  return (
    <Head>
      <meta property="og:url" content={currentUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={facebookText} />
      <meta property="og:image" content={facebookImageUrl} />
      <meta property="og:image:height" content="200" />
      <meta property="og:image:width" content="200" />
      <meta property="og:image:alt" content={`${orgName} share image`} />
      <meta property="fb:app_id" content={facebookAppId} />
    </Head>
  )
}
