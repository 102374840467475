import { logger } from 'utils/logger'
export interface IntelligentAskAmountEventPayload {
  isLoading: boolean
  suggested_donation_amount: string | null
  suggested_recurring_amount: string | null
  grid: {
    suggested_donation_amount: number[] | null
    suggested_recurring_amount: number[] | null
  }
}

export const getIntelligentAskAmount = async (campaignId?: string) => {
  let iaaBaseAmounts = {
    suggested_donation_amount: null,
    suggested_recurring_amount: null,
    grid: {
      suggested_donation_amount: null,
      suggested_recurring_amount: null,
    },
  }

  try {
    const response = await fetch('/api/intelligentAskAmount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // Abort the fetch if it takes longer than 1 second
      signal: AbortSignal.timeout(1000),
      body: JSON.stringify({
        campaignId: campaignId,
      }),
    })

    const json = await response.json()

    iaaBaseAmounts = {
      suggested_donation_amount: json?.suggested_donation_amount,
      suggested_recurring_amount: json?.suggested_recurring_amount,
      grid: { ...json?.grid },
    }
  } catch (e) {
    if ((e as Error).name === 'TimeoutError') {
      logger(
        'info',
        new Error(
          `Unable to fetch Intelligent Ask Amount in less than 1 second for ${campaignId}`,
          {
            cause: e,
          },
        ),
      )
    } else {
      logger(
        'error',
        new Error(`Unable to fetch Intelligent Ask Amount for ${campaignId}`, {
          cause: e,
        }),
      )
    }
  } finally {
    /**
     * iaa will return null for a suggested amount
     * if the suggested amount is not enabled by the campaign
     *
     * { suggested_donation_amount: number, suggested_recurring_amount: null}
     */
    return iaaBaseAmounts
  }
}
